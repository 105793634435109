import React, { useState, useEffect } from 'react';
import korrectLogo from '../../../assets/images/korrectLogoIcon.png';
import adminLogo from '../../../assets/images/app.png';
import assetsCompareServices from './assetsCompareServices';
import Pagination from '../../Header/Pagination';

export default function AssetCompare() {
    
    let [totalRows, setTotalRows] = useState(0);
    let [searchFor, setsearchFor] = useState("");
    let [rawData, setRawData] = useState<any>([]);
    let [exclude, setExclude] = useState<any>([]);
    let [exportAll, setExportAll] = useState(true);
    let [openInclude, setOpenInclude] = useState(false);
    let [categoryList, setCategoryList] = useState<any[]>([]);
    let [assetListMethod, setAssetListMethod] = useState("existDifference");
    let [adminAssetsListPagination, setAdminAssetsListPagination] = useState<any[]>([]);
    let [korrectAssetsListPagination, setKorrectAssetsListPagination] = useState<any[]>([]);

    let [criteriaParamsSelection, setCriteriaParamsSelection] = useState<any[]>([
        { selection: false, value: "Release Title", criteria: 'RTitle' },
        { selection: false, value: "Track Title", criteria: 'TTitle' },
        { selection: false, value: "Release Artist", criteria: 'RArtist' },
        { selection: false, value: "Track Artist", criteria: 'TArtist' },
        { selection: false, value: "ISRC", criteria: 'ISRC' },
        { selection: false, value: "Catalog Number", criteria: 'catlogNum' },
        { selection: false, value: "Custom ID", criteria: 'CustomID' }
    ]);

    const removeTabName = (index: any) => {
        criteriaParamsSelection[index].selection = false;
        setCriteriaParamsSelection(existingValues => [...criteriaParamsSelection]);
    }

    const addTabName = (index: any) => {
        criteriaParamsSelection[index].selection = true;
        setCriteriaParamsSelection(existingValues => [...criteriaParamsSelection]);
    }

    useEffect(() => {

        addTabName(0);
        addTabName(4);
        getAssetCompareData();

    }, [])

    const getAssetCompareData = () => {

        setAssetListMethod(assetListMethod);

        const criteriaParams = {
            link: "https://master.dws03r4d6820k.amplifyapp.com/api/assets/",
            xapikey: "25520aaf-e317-4794-822f-8aae77b48c28",
            searchFor: searchFor,
            method: assetListMethod
        }

        for (var $i = 0; $i < criteriaParamsSelection?.length; $i++) {
            if (criteriaParamsSelection[$i].selection) {
                Object.assign(criteriaParams, { [criteriaParamsSelection[$i].criteria]: true });
            } else {
                Object.assign(criteriaParams, { [criteriaParamsSelection[$i].criteria]: false });
            }
        }

        assetsCompareServices.getAssetCompare(criteriaParams).then((res) => {

            setRawData(res[0]);
            setAdminAssetsListPagination(res[0].adminAssets.slice((0) * 10, (10)));
            setKorrectAssetsListPagination(res[0].korrectAssets.slice((0) * 10, (10)));

            if(assetListMethod == "nonexistPortal"){
                console.log(res[0]);
                setTotalRows(res[0]?.korrectAssets.length)
            }
            if(assetListMethod == "nonexistKorrect"){
                console.log(res[0]);
                setTotalRows(res[0]?.adminAssets.length)
            }
            if(assetListMethod == "existDifference"){
                console.log(res[0]);
                setTotalRows(res[0]?.adminAssets.length)
            }
        })

    }

    const exportListings = (value: any) => {
        console.log('ss');
        let arrayList: any = [];

        if (value == 'korrectAsset') {
            var obj = adminAssetsListPagination.filter((asset: any) => (asset?.selected === true));
            console.log(obj);

            for (var $i = 0; $i < obj?.length; $i++) {
                arrayList.push({ ISRC: obj[$i].ISRC, catalogNumber: obj[$i].releaseCode });
            }
        }

        if (value == 'adminAsset') {
            var obj1 = korrectAssetsListPagination.filter((asset: any) => (asset?.selected === true));
            console.log(obj1);
            for (var $i = 0; $i < obj1?.length; $i++) {
                arrayList.push({ ISRC: obj1[$i].ISRC, catalogNumber: obj1[$i].catalogNumber });
            }
        }

        if (exportAll) {
            var obj3 = korrectAssetsListPagination;
            console.log(obj3);
            arrayList = []
        }

        const criteriaParams = {
            link: "https://master.dws03r4d6820k.amplifyapp.com/api/assets/",
            xapikey: "25520aaf-e317-4794-822f-8aae77b48c28",
            exportType: value,
            searchFor: searchFor,
            assetList: arrayList
        }
        console.log(criteriaParams);

        assetsCompareServices.exportAssetCompare(criteriaParams).then((res) => { });

    }

    const onOptionChange = (type: any, index: any) => {
        var KTCode = korrectAssetsListPagination[index]?.ISRC ? korrectAssetsListPagination[index].ISRC : ""
        var KRCode = korrectAssetsListPagination[index]?.catalogNumber ? korrectAssetsListPagination[index].catalogNumber : ""

        var ATCode = adminAssetsListPagination[index]?.ISRC ? adminAssetsListPagination[index].ISRC : ""
        var ARCode = adminAssetsListPagination[index]?.releaseCode ? adminAssetsListPagination[index].releaseCode : ""

        if (type == 'korrect' && KRCode != "" && KTCode != "") {
            if (korrectAssetsListPagination[index]) {
                korrectAssetsListPagination[index].selected = true;
            }

            if (adminAssetsListPagination[index]) {
                adminAssetsListPagination[index].selected = false
            }
        }

        if (type == 'admin' && ATCode != "" && ARCode != "") {

            if (korrectAssetsListPagination[index]) {
                korrectAssetsListPagination[index].selected = false;
            }

            if (adminAssetsListPagination[index]) {
                adminAssetsListPagination[index].selected = true
            }

        }

        setAdminAssetsListPagination(adminAssetsListPagination);
        setKorrectAssetsListPagination(korrectAssetsListPagination);

    }

    const compareDataField = (firstVal: any, secondVal: any, index : any) => {
        let tempText  = 'bg-level10';
        if(index == 1){
            tempText  = 'bg-level16';
        }


        if (firstVal && !secondVal) {
            return tempText
        }

        if (!firstVal && secondVal) {
            return tempText
        }


        if (firstVal != secondVal) {
            return tempText
        }

        if (firstVal == secondVal) {
            return ""
        }
    }

    const searchSummary = (e: any) => {
        setsearchFor(e);
        console.log(e);
        console.log(rawData);

        if (e && e.length > 0) {
            var tempKorrect = [];
            var tempAdmin = [];

            for (var $i = 0; $i < rawData.korrectAssets?.length; $i++) {
                var flag = 0;
                var filter = [];

                filter = [rawData.korrectAssets[$i]].filter(item => item.trackTitle?.toLocaleLowerCase().includes(e.toLocaleLowerCase()));

                if (filter.length > 0) {
                    flag = 1;
                } else {
                    filter = [rawData.adminAssets[$i]].filter(item => item.trackTitle?.toLocaleLowerCase().includes(e.toLocaleLowerCase()));
                    console.log(filter);

                    if (filter.length > 0) {
                        flag = 1;
                    }
                }

                if (flag == 1) {
                    tempKorrect.push(rawData.korrectAssets[$i]);
                    tempAdmin.push(rawData.adminAssets[$i]);
                }

            }

            setAdminAssetsListPagination(tempAdmin);
            setKorrectAssetsListPagination(tempKorrect);
        } else {

            setAdminAssetsListPagination(rawData.adminAssets);
            setKorrectAssetsListPagination(rawData.korrectAssets);
        }
    }

    const paginationHandler = (n: any) => {
        console.log(n);
        setAdminAssetsListPagination(rawData.adminAssets.slice((n.pageNumber - 1) * n.pageSize, (n.pageSize * n.pageNumber)));
        setKorrectAssetsListPagination(rawData.korrectAssets.slice((n.pageNumber - 1) * n.pageSize, (n.pageSize * n.pageNumber)));
    }

    return (<>
        <div className="landing ws-12">

            <div className='ws-12 pLarge'>

                <div className="ptxl mtl">
                    <div className="ws-9">
                        <span className="ws-12 text-bold text-xl">Asset Management</span>
                        <span className="ws-12 ptl text-bold text-m turquoise">Asset Compare</span>
                    </div>

                    <div className="ws-12 ptxl">

                        <div className="ws-12 mbl">
                            <button className="btn white pLarge bg-level14 m0 ws-2" onClick={() => searchSummary(searchFor)}> Search</button>
                            <input type="text" value={searchFor} onChange={(e) => setsearchFor(e.target.value)} className="pLarge ws-10 m0 heightReset" placeholder="Type to search" />
                        </div>

                    </div>

                    <div className='ws-12 bgWhite'>

                        <div className="ws-12 box">

                            <div className="pm ws-12">
                                <span className="pull-left pt text-bold text-m mr mt">Criteria : </span>

                                {criteriaParamsSelection?.map((item: any, index: any) => {
                                    return (
                                        <>{<div className={item.selection ? 'tagging bg-level1 mr forceSingleLine' : 'hide'}>
                                            <span className="bgTransparent text-bold">{item.value}</span>
                                            <a className="btn-info pull-left btn-xxs borderNone bgTransparent m0" onClick={() => removeTabName(index)}><i className="icon-cross1 text-s mt"></i></a>
                                        </div>}</>
                                    )
                                })}

                                <a className="p0 pull-left btn-success borderNone bgTransparent m0" onClick={() => setOpenInclude(!openInclude)}><i className="icon-plus text-xxl"></i></a>
                            </div>

                            {openInclude && true && <> <hr />
                                <div className="pm ws-12">

                                    {criteriaParamsSelection?.map((item: any, index: any) => {
                                        return (
                                            <>{<div className={item.selection ? 'hide' : "tagging bg-level1 mr forceSingleLine"}>
                                                <span className="bgTransparent text-bold">{item.value}</span>
                                                <a className="btn-info pull-left btn-xxs borderNone bgTransparent m0" onClick={() => { addTabName(index) }}><i className="icon-plus"></i></a>
                                            </div>}</>
                                        )
                                    })}

                                    {categoryList?.map((list: any) => {
                                        return (<>
                                            {(() => {
                                                if (exclude.filter((e: any) => e == list).length == 0) {
                                                    return (
                                                        <div className="tagging bg-level1 mr forceSingleLine">
                                                            <span className="bgTransparent text-bold">{list}</span>
                                                            <a className="btn-info pull-left btn-xxs borderNone bgTransparent" onClick={() => addTabName(list)}><i className="icon-plus2 text-s mt"></i></a>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                        </>)
                                    })}
                                </div>

                            </>}

                            <div className="ws-12 pm">

                                <ul className='nav nav-tabs ws-12 mbll mtll  ws-6'>
                                    <li className={assetListMethod == 'nonexistPortal' ?  'active' : ''} onClick={() => { assetListMethod = 'nonexistPortal'; getAssetCompareData(); }}>On Korrect Only</li>
                                    <li className={assetListMethod == 'nonexistKorrect' ? 'active' : ''} onClick={() => { assetListMethod = 'nonexistKorrect'; getAssetCompareData(); }}>On Admin Only</li>
                                    <li className={assetListMethod == 'existDifference' ? 'active' : ''} onClick={() => { assetListMethod = 'existDifference'; getAssetCompareData(); }}>Different</li>
                                </ul>

                                <div className='mbll mtll pull-right'>

                                    {(assetListMethod == 'nonexistKorrect' || assetListMethod == 'existDifference') && <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { exportListings('korrectAsset') }}>Export For Korrect</a>}
                                    {(assetListMethod == 'nonexistPortal' || assetListMethod == 'existDifference') && <a className="btn bg-level7 white pull-right pm btn-m forceSingleLine" onClick={() => { exportListings('adminAsset') }}>Export For Admin </a>}

                                    <div className='pull-right mrl'>
                                        <span className='mtl ml pull-right'>Export All</span>

                                        <label className="switch mt">
                                            <input type="checkbox" checked={exportAll} name="creatorFlag" onClick={() => setExportAll(!exportAll)} />
                                            {exportAll && <span className="slider round text-left white pm pts " >Yes</span>}
                                            {!exportAll && <span className="slider round text-right white pm pts sliderOff">No</span>}
                                        </label>
                                    </div>

                                </div>
                            </div>

                            {(totalRows > 0 ) && <div className={'ws-12 pm ptl pt pb'}><span className="text-bold text-m">Showing <span className="info">{totalRows}</span> results</span></div>}

                            {(totalRows > 0 ) && <div className='ws-12 mtl pLarge tableVertical'>
                                <table className={'tableVertical ws-12'}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className="text-center">ISRC</th>
                                            <th className="text-center">Track Title</th>
                                            <th className="text-center">Track Artist</th>
                                            <th className="text-center">Release Code</th>
                                            <th className="text-center">Release Title</th>
                                            <th className="text-center">Release Artist</th>
                                            <th className="text-center">CustomID</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {assetListMethod == 'existDifference' && <> {korrectAssetsListPagination?.map((list: any, index: any) => {
                                            return (
                                                <>
                                                    <tr className='bg-level1'>
                                                        <td width='60' className='ptl pbl'> <input className='mt mrl' type='radio' name={index} onChange={(e) => onOptionChange("korrect", index)} />  <img className="pull-none" src={korrectLogo} width={20} /></td>
                                                        <td width="200" className={compareDataField(list?.ISRC, adminAssetsListPagination[index]?.ISRC, 0) + " text-uppercase"}            >{list?.ISRC}</td>
                                                        <td className={compareDataField(list?.trackTitle, adminAssetsListPagination[index]?.trackTitle, 0) + " text-uppercase"}      >{list?.trackTitle}</td>
                                                        <td width="200" className={compareDataField(list?.trackArtist, adminAssetsListPagination[index]?.trackArtist, 0) + " text-uppercase"}     >{list?.trackArtist}</td>
                                                        <td width="200" className={compareDataField(list?.catalogNumber, adminAssetsListPagination[index]?.releaseCode, 0) + " text-uppercase"}     >{list?.catalogNumber}</td>
                                                        <td className={compareDataField(list?.name, adminAssetsListPagination[index]?.releaseName, 0) + " text-uppercase"}     >{list?.name}</td>
                                                        <td width="200" className={compareDataField(list?.artist, adminAssetsListPagination[index]?.releaseArtist, 0) + " text-uppercase"}   >{list?.artist}</td>
                                                        <td width="200" className={compareDataField(list?.customID, adminAssetsListPagination[index]?.customID, 0) + " text-uppercase"}        >{list?.customID}</td>
                                                    </tr>

                                                    <tr className='bg-level8'>
                                                        <td className='ptl pbl'> <input className='mt mrl' type='radio' name={index} value='admin' onChange={(e) => onOptionChange("admin", index)} /> <img className="pull-none" src={adminLogo} width={20} /> </td>
                                                        <td className={compareDataField(list?.ISRC, adminAssetsListPagination[index]?.ISRC, 1) + " text-uppercase"}            >{adminAssetsListPagination[index]?.ISRC}</td>
                                                        <td className={compareDataField(list?.trackTitle, adminAssetsListPagination[index]?.trackTitle, 1) + " text-uppercase"}      >{adminAssetsListPagination[index]?.trackTitle}</td>
                                                        <td className={compareDataField(list?.trackArtist, adminAssetsListPagination[index]?.trackArtist, 1) + " text-uppercase"}     >{adminAssetsListPagination[index]?.trackArtist}</td>
                                                        <td className={compareDataField(list?.catalogNumber, adminAssetsListPagination[index]?.releaseCode, 1) + " text-uppercase"}     >{adminAssetsListPagination[index]?.releaseCode}</td>
                                                        <td className={compareDataField(list?.name, adminAssetsListPagination[index]?.releaseName, 1) + " text-uppercase"}     >{adminAssetsListPagination[index]?.releaseName}</td>
                                                        <td className={compareDataField(list?.artist, adminAssetsListPagination[index]?.releaseArtist, 1) + " text-uppercase"}   >{adminAssetsListPagination[index]?.releaseArtist}</td>
                                                        <td className={compareDataField(list?.customID, adminAssetsListPagination[index]?.customID, 1) + " text-uppercase"}        >{list?.customID}</td>
                                                    </tr>
                                                    <tr>
                                                        <td > <span className='ps ws-12'> &nbsp;</span></td>
                                                        <td className="text-uppercase"></td>
                                                        <td className="text-uppercase"></td>
                                                        <td className="text-uppercase"></td>
                                                    </tr>
                                                </>
                                            )
                                        })}</>}

                                        {assetListMethod == 'nonexistKorrect' && <> {adminAssetsListPagination?.map((list: any, index: any) => {
                                            return (
                                                <>
                                                    <tr className='bg-level1'>
                                                        <td width='60' className='ptl pbl'> <input className='mt mrl' type='radio' name={index} onChange={(e) => onOptionChange("korrect", index)} />  <img className="pull-none" src={korrectLogo} width={20} /></td>
                                                        <td className=" text-uppercase">{list?.ISRC}</td>
                                                        <td className={" text-uppercase"}>{list?.trackTitle}</td>
                                                        <td className={" text-uppercase"}>{list?.trackArtist}</td>
                                                        <td className={" text-uppercase"}>{list?.releaseCode}</td>
                                                        <td className={" text-uppercase"}>{list?.releaseName}</td>
                                                        <td className={" text-uppercase"}>{list?.releaseArtist}</td>
                                                        <td className={" text-uppercase"}>{list?.customID}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}</>}

                                        {assetListMethod == 'nonexistPortal' && <> {korrectAssetsListPagination?.map((list: any, index: any) => {
                                            return (
                                                <>
                                                    <tr className='bg-level8'>
                                                        <td className='ptl pbl'> <input className='mt mrl' type='radio' name={index} value='admin' onChange={(e) => onOptionChange("admin", index)} /> <img className="pull-none" src={adminLogo} width={20} /> </td>
                                                        <td className={"text-uppercase"}            >{list?.ISRC}</td>
                                                        <td className={"text-uppercase"}      >{list?.trackTitle}</td>
                                                        <td className={"text-uppercase"}     >{list?.trackArtist}</td>
                                                        <td className={"text-uppercase"}     >{list?.releaseCode}</td>
                                                        <td className={"text-uppercase"}     >{list?.releaseName}</td>
                                                        <td className={"text-uppercase"}   >{list?.releaseArtist}</td>
                                                        <td className={"text-uppercase"}        >{list?.customID}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}</>}

                                    </tbody>
                                </table>
                            </div>}

                            {(totalRows == 0 ) && <div className={'ws-12 pbxl mbl'}><span className="text-center text-bold ws-12 mtl"> No Data Available. </span></div>}

                        </div>                        

                        <div className="clearfix ws-12 pm">
                            {(() => {
                                if (totalRows > 10) {
                                    return <Pagination onChange={paginationHandler} totalRows={totalRows} />
                                }
                            })()}
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </>)
}