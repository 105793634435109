import React, { Suspense, useState, useEffect } from "react";
import HorizontalBarChart from "../Charts/HorizontalBarChart"; 
import AreaChart from "../Charts/AreaChart"; 
import directoryServices from "./directoryServices";
import { CommonUtilities } from "../../commonUtils";
import { format } from "date-fns";
import PeriodSelectionForm from "../PeriodSelection/periodSelectionForm";
import ContainerSlider from "./Analytic/ContainerSlider";
import { Filter } from "../../filter";
import VerticalBarChart from "../Charts/VerticalBarChart";
import Pagination from "../Header/Pagination";

export default function Summary(param: any) {

    let [sumGross , setSumGross] =useState(0);    
    let [searchFor, setsearchFor] = useState("");
    let [sumPaidOut, setSumPaidOut] =useState(0);
    let [TEtotalRow, setTETotalRows] = useState(0); 
    let [reportingTo, setreportingTo] =useState("");     
    let [TAStotalRow, setTASTotalRows] = useState(0); 
    let [TATtotalRow, setTATTotalRows] = useState(0); 
    let [TETfotalRows, setTETfotalRows] = useState(0); 
    let [reportingFrom, setreportingFrom] =useState(""); 
    let [TopImpCusRows, setTopImpCusRows] = useState(0); 
    let [DSPSummay  , setDSPSummay] = useState<any>([]); 
    let [transactionRef,settransactionRef] =useState("");   
    let [summaryList, setSummaryList] = useState<any>([]);
    let [TopExtract  , setTopExtract] = useState<any>([]);
    let [ImpCusSummay  , setTopImpCus] = useState<any>([]);  
    let [AssetSummay  , setAssetSummay] = useState<any>([]);
    let [ArtistSummay  , setArtistSummay] = useState<any>([]);
    let [FormatSummay  , setFormatSummay] = useState<any>([]);
    let [ImprintSummay  , setImprintSummay] = useState<any>([]);     
    let [periodSelection, setperiodSelection] = useState(false);
    let [dialogueOpenView,setdialogueOpenView] = useState(false);
    let [DSPGraphSummay  , setDSPGraphSummay] = useState<any>([]);
    let [TerritorySummay  , setTerritorySummay] = useState<any>([]);
    let [TerritoryFormatSummay  , setTopTerritoryFormat] = useState<any>([]); 
    let [activePerformanceTab,setactivePerformanceTab] = useState("Performance");

    useEffect(() => {        
        setreportingFrom( String(Filter.DateTimeFormatNumber(global.periodSelected.periodFrom)) );
        setreportingTo( String(Filter.DateTimeFormatNumber(global.periodSelected.periodTo)) );
        ApiCall("TopALL");

    }, [])

    var pagination = {
        pageSize        : 10,
        pageNumber      : 1, 
        type            : "",
        transaction_Ref : transactionRef,
        periodTo        : reportingTo && reportingTo.length >0 ? reportingTo :String(Filter.DateTimeFormatNumber(global.periodSelected.periodTo)),
        periodFrom      : reportingFrom && reportingFrom.length >0 ? reportingFrom :String(Filter.DateTimeFormatNumber(global.periodSelected.periodFrom)),
        Section         : activePerformanceTab,
        searchFor       : searchFor
    }  
    
    const ApiCall = (type :any)=>{ 
        pagination = {...pagination ,...param.code}
        if(type == "TopALL" ){
            sumGross = 0
            sumPaidOut = 0 
            setSumGross( sumGross ) ;
            setSumPaidOut( sumPaidOut ) ; 
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{  
                setSummaryList(res);
                for(var $i=0 ; $i < res.length ; $i++){
                    sumGross = sumGross+res[$i].totalGross 
                    setSumGross(sumGross);
                    setSumPaidOut( sumPaidOut  +res[$i].totalPaidOut ); 
                } 
            })
        }

        if(type == "TopArtists" || type == "TopALL" ){
            setArtistSummay({})
            pagination.type = "TopArtists"
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{  setArtistSummay(res); setTATTotalRows(res && res[0] && res[0].totalRows >0 ?res[0].totalRows : 0)  });
        }

        if(type == "TopAssets" || type == "TopALL" ){
            setAssetSummay({})
            pagination.type = "TopAssets"
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{  setAssetSummay(res); setTASTotalRows(res && res[0] && res[0].totalRows >0 ?res[0].totalRows : 0)  });    
        }         

        if(type == "TopLabels" || type == "TopALL" ){
            setImprintSummay({})
            pagination.type = "TopLabels"
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{  setImprintSummay(res) }) ;    
        }        

        if(type == "TopTerritory" || type == "TopALL" ){
            setTerritorySummay({})
            pagination.type = "TopTerritory"
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{  setTerritorySummay(res) }) ;            
        }

        if(type == "TopFormat" || type == "TopALL" ){
            setFormatSummay({})
            pagination.type = "TopFormat"
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{  setFormatSummay(res) });    
        }      

        if((type == "TopImpCus" || type == "TopALL") &&  activePerformanceTab != "Performance"){
            setTopImpCus({})
            pagination.type = "TopImpCus"
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{   setTopImpCus(res); setTopImpCusRows(res && res[0] && res[0].totalRows >0 ?res[0].totalRows : 0) });          
        }   
        
        if((type == "TopTerritoryFormat" || type == "TopALL" ) && activePerformanceTab != "Performance"){
            setTopTerritoryFormat({})
            pagination.type = "TopTerritoryFormat"
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{   setTopTerritoryFormat(res); setTETfotalRows(res && res[0] && res[0].totalRows >0 ?res[0].totalRows : 0) });          
        } 

        if(type == "TopAccounts" || type == "TopALL" ){
            DSPSummay= []
            setDSPSummay(DSPSummay)
            pagination.type = "TopAccounts"
            pagination.pageSize = 5
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{  setDSPSummay(res) }) ;    
        } 

        if(type == "TopGraphAccounts" || type == "TopALL" ){
            setDSPGraphSummay({})
            pagination.type = "TopGraphAccounts"
            pagination.pageSize = 500
            directoryServices.singlePageSummaryListing(pagination).then((res:any)=>{  setDSPGraphSummay(res) }) ;    
        }         
    } 

    const CallChart = (data : any , name :any) => {

        var backgroundColor     : any = []; 
        var percentageGross     : any = [];
        var percentagePaidOut   : any = []; 
        var labelsData          : any = [];    
    
        for (var i = 0; i < data?.length; i++) { 
            percentageGross.push(data[i].totalGross);
            percentagePaidOut.push(data[i].totalPaidOut); 
            if(name == "Period"){
                labelsData.push(data[i].royaltyPeriod)
            };
            
            if(name == "Asset"){
                labelsData.push(data[i].assetTitle)
            };

            if(name == "Artist"){
                labelsData.push(data[i].assetArtist)
            };

            if(name == "DSP"){
                labelsData.push(data[i].royaltyPeriod)
            };

            if(name == "Imprint"){
                labelsData.push(data[i].assetImprint)
            };
            
            if(name == "Territory"){ 
                labelsData.push(data[i].territoryCode)
            };

            if(name == "Format"){
                labelsData.push(data[i].formatCode)
            };
            

            for (var j = 0; j < CommonUtilities.chartColors?.length; j++) {
                backgroundColor.push(CommonUtilities.chartColors[j]);
            }
        }

        const options = {
            layout  : { padding : { top: 0 } },
            plugins : { elements: { bar: { borderWidth: 0, }, } }
        }; 

        var dataObj = {
            labels: labelsData,
            datasets: [
        /*        {
                    label           : 'Total PaidOut',
                    data            : percentagePaidOut,
                    borderColor     : backgroundColor[0],
                    backgroundColor : backgroundColor[0],
                    yAxisID         : 'y',
                }, */
                {
                    label           : 'Total Gross',
                    data            : percentageGross,
                    borderColor     : backgroundColor[1],
                    backgroundColor : backgroundColor[1],
                    yAxisID         : 'y',
                } 
            ]
        };  
    
        return {options : options ,data : dataObj} ;        
    }
 
    const CallChartDSP = (data : any , name :any) => {
   
        var percentageGross     : any = [];
        var labelsData          : any = [];    
        var datasets            : any = [];
        var labelName           : any = [];
        var topSelection        : any = [];

        for (var i = 0; i < data?.length; i++) {
            if( (labelsData?.filter((e:any)=> e == data[i].royaltyPeriod)).length == 0){
                labelsData.push(data[i].royaltyPeriod);
            }
        }

        const sortedArray   = [...labelsData].sort((a, b) => a - b);
        labelsData          = []
        labelsData          = sortedArray

        for (var i = 0; i < DSPSummay?.length; i++) {
            if( (labelName?.filter((e:any)=> e == DSPSummay[i].subProvider?.toLocaleLowerCase())).length == 0){
 
                labelName.push(DSPSummay[i].subProvider?.toLocaleLowerCase());
            } 
        }        
        
        for(var y = 0; y < labelName?.length; y++){
            percentageGross = []
            for(var i = 0; i < labelsData?.length; i++){
                const sumtotalGross = data?.filter((e:any) => e.subProvider?.toLocaleLowerCase() ==labelName[y] && e.royaltyPeriod ==labelsData[i] );
                percentageGross.push( sumtotalGross[0] && sumtotalGross[0].totalGross ?  sumtotalGross[0].totalGross : 0 )
            }

            datasets.push( {
                label           : CommonUtilities.headerName(labelName[y]  )    , data      : percentageGross,
                borderColor     : CommonUtilities.getDSPColor(labelName[y] )    ,
                backgroundColor : CommonUtilities.getDSPColor(labelName[y] )    , yAxisID   : 'y',
            })
    
        }

        const options = {   layout  : { padding : { top: 0 }  },  plugins : { elements: { bar: { borderWidth: 0, }, } } , interaction: {  mode: 'index', intersect: false,  }  ,tension: 0.4 ,}; 
        
        for(var y = 0; y < datasets?.length; y++){ 
            if(y < 5){
                topSelection.push(datasets[y]); 
            }
        } 
 
        var dataObj = {  labels: labelsData,  datasets: topSelection };       
        
        return {options : options ,data : dataObj} ;        
    }

    const closePeriod = (e: any) => { 
        periodSelection = false;
        setperiodSelection(false)
    }

    const onChange = (period: any) => {
    
        if (period.periodFrom) {
            pagination.periodFrom = period.periodFrom.year + format(new Date(period.periodFrom.month), 'MMdd');
            reportingFrom = period.periodFrom.year + format(new Date(period.periodFrom.month), 'MMdd');
            setreportingFrom(period.periodFrom.year + format(new Date(period.periodFrom.month), 'MMdd')); 
        }

        if (period.periodTo) {           
            pagination.periodTo = period.periodTo.year + format(new Date(period.periodTo.month), 'MMdd');
            reportingTo = period.periodTo.year + format(new Date(period.periodTo.month), 'MMdd') ;
            setreportingTo(period.periodTo.year + format(new Date(period.periodTo.month), 'MMdd')) ;
        }

        ApiCall("TopALL");
    
    }

    const onChangeClose =(e:any)=>{
        console.log(e)
        if(e && e.action === 'search'){ 
            pagination.transaction_Ref = e.tranRef;  
            settransactionRef(e.tranRef);   
            ApiCall("TopALL");
        }

        if(e && e.action === 'Close'){
            console.log("Close")
        }
        setdialogueOpenView(false);
    }
    
    const paginationTE = (e:any) =>{
        pagination.pageNumber= e.pageNumber ;  
        pagination.pageSize= e.pageSize ; 
        ApiCall("TopExtract");        
    }

    const paginationTAS = (e:any) =>{
        pagination.pageNumber= e.pageNumber ;  
        pagination.pageSize= e.pageSize ; 
        ApiCall("TopAssets");        
    }

    const paginationTAT = (e:any) =>{
        pagination.pageNumber= e.pageNumber ;  
        pagination.pageSize= e.pageSize ; 
        ApiCall("TopArtists");        
    } 
    
    const tabChange = (e:any) =>{
        setactivePerformanceTab(e);
        sumGross = 0
        sumPaidOut = 0 
        setSumGross( sumGross ) ;
        setSumPaidOut( sumPaidOut ) ; 
        pagination.Section = e;
        ApiCall("TopALL");
    }
   
    const paginationTIC = (e:any) =>{
        console.log(e)  
        pagination.pageNumber= e.pageNumber ;  
        pagination.pageSize= e.pageSize ; 
        if(e == 'Financial'){
            ApiCall("TopImpCus"); 
        }       
    }

    const paginationTTF = (e:any) =>{
        console.log(e)  
        pagination.pageNumber= e.pageNumber ;  
        pagination.pageSize= e.pageSize ; 
        ApiCall("TopTerritoryFormat");        
    }

    const searchSummary =(e:any) =>{
        setsearchFor(e);
        ApiCall("TopALL");
    }

    return (<> 
        <div className='ws-12 ptl'>                          

            <div className="ws-12 mbl pr">   

                <div className="pull-left mrxl">
                    <a className={activePerformanceTab=='Performance' ? 'btn-success active m0' : 'btn-success m0'} onClick={()=>{tabChange('Performance');  }}>Performance</a>
                    <a className={activePerformanceTab=='Financial'   ? 'btn-success active ' : 'btn-success '} onClick={()=>{tabChange('Financial');}}>Royalties</a>                                        
                </div>

                <div className="ws-4">                  
                    <button className="btn white bg-level7 p8 ws-3 m0 pull-right" onClick={()=>searchSummary(searchFor)}> Search  </button>
                    <input type="text" value={searchFor} onChange={(e) => setsearchFor(e.target.value)}  className="p7 ws-8 heightReset border-green pull-right"  placeholder="Click search to show data " /> 
                </div>

                <div className="ws-4 pull-right">
                    <button className="btn-queue pull-right m0" onClick={()=>setdialogueOpenView(true)}>Filter </button>

                    <div className="pull-left pull-right">
                        <div className="centerWindow ws-12">
                            <a className="btn-success" onClick={()=>setperiodSelection(true)}>

                                <i className="icon-angle-right text-m pull-right mll"></i>

                                <span className="pull-right success"> {Filter.NumToDate(reportingTo)}</span>
                                
                                <i className="icon-vertical-line ml pull-right mr text-sl success"></i>

                                <span className="pull-right success">{Filter.NumToDate(reportingFrom)}</span>

                            </a>
                        </div>
                    </div> 
                </div>                      
            </div>
        
            <div className={ summaryList?.length <= 0 ? 'ws-12 ptl mtl bgWhite full-height-vh90': 'hide'}><div className="ws-12"><span className="text-center text-bold ws-12 mtl grey"> Not actioned. </span></div></div>
        
            <div className={summaryList?.length > 0 ? 'ws-12': 'hide'}>
                {<div className="ws-12 mbl p1 pr">    
                    <h2 className={ summaryList && summaryList?.length == 0 ? 'grey': ''} >Income Analysis</h2>                          
                    <div className="box ws-12 pt pbl bgWhite">
                    {(summaryList?.length > 0) && <div className="ws-12"> 
                        <div className="pll prl ptl pbl ws-12">
                            
                            <div className='ws-12 borderSolid pm'>  
                                        
                                <span className='ws-2 text-bold pull-left pull-left'>Income Summary</span>

                                <div className="ws-10"> 
                                    <div className="ws-6">
                                        <span className='ws-6 pull-left success text-bold'> Total Gross : {CommonUtilities.getCSymbols(global.clientDetail.homeCurrency)} {sumGross?.toFixed(2) } </span>
                                        <span className='pull-right danger text-bold hide'>  Paid Out   : {CommonUtilities.getCSymbols(global.clientDetail.homeCurrency)} {sumPaidOut?.toFixed(2) } </span>
                                    </div>
                                
                                    <div className="ws-6">

                                        {(summaryList?.length > 0) && <div className="ws-12 verticalMediumWide hover-box pull-right">
                                            <div className="ws-12 hover-block textboxDropdownList box defaultColor rightReset">
                                                <div className="allowHscroll allowVscroll pm"> 
                                                    <span className='ws-12 text-bold pull-right pb'>Income Per Period</span>
                                                    <span className='ws-4 pull-left pb pt'>Period</span>
                                                    <span className='ws-4 pull-right pb pt text-center hide'>PaidOut</span>
                                                    <span className='ws-4 pull-right pb pt text-center'>Gross</span>
                                                    { summaryList?.map((value:any , index :any)=>{
                                                        return(
                                                        <div className='ws-12 box pm mb pull-right'>  
                                                            <span className='ws-4 pull-left text-bold' > {value.royaltyPeriod} </span>
                                                            <span className='ws-4 pull-right text-center hide'style={{color:"red"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {value.totalPaidOut?.toFixed(2) } </span>
                                                            <span className='ws-4 pull-right text-center'style={{color:"green"}}>    {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {value.totalGross?.toFixed(2) } </span>
                                                        </div>
                                                        )
                                                    })}
                                                </div>

                                            </div>

                                            <a className="btn-info pull-right noBorder p0"><i className="icon-info1 m0 p0"></i></a>
                                        </div>} 
                                    </div>

                                </div>
                            </div> 

                            <div className="w-25 ptll" > 
                            {DSPSummay?.map((value:any , index :any)=>{

                                if(index < 6){
                                    return( 
                                    <div className="ws-12 pm mt mb mt mb" style={{borderLeft : 'solid 3px' + CommonUtilities.getDSPColor(value.subProvider)}}> 
                                        
                                        <div className="displayBlock pull-left">
                                            <span className="text-bold ws-12 text-xl mbl">{CommonUtilities.getCSymbols( global.clientDetail?.homeCurrency)} {value.totalGross?.toFixed(2)}   </span>
                                            <span style={{color : CommonUtilities.getDSPColor(value.subProvider)}} className="ws-12 ">{value.subProvider}       </span>
                                        </div>
                                        
                                        <img className="pull-right" height="44" src={"data:image/jpeg;base64,"+value.dspImage} onError= { (e : any) => { e.src='../../../../assets/images/korrectLogoIcon.png' } }/> 
                                          
                                    </div>)
                                }
                            })}
                            </div>

                            <div className='w-75 pull-right mtl pLarge'> 
                                <AreaChart chartPassedData={ CallChartDSP(DSPGraphSummay,"DSP") }/>
                            </div>                        
                        </div> 
                    </div>}  

                    {(summaryList && summaryList?.length == 0) && <div className="ws-12"><span className="text-center text-bold ws-12 mtl grey"> Not actioned. </span></div>} 
                    </div>
                </div>}  

                <div className="ws-12 mbl p1 prl hide">
                    <h2 className={ ArtistSummay && ArtistSummay?.length == 0 ? 'grey': ''}>Top Artist</h2>
                    <div className='ws-12 pbl bgWhite'> 

                        <div className='ws-12 hide'>
                            <div className='ws-6'>
                                <HorizontalBarChart chartPassedData={ CallChart(ArtistSummay,"Artist") }/>
                            </div>

                            {(ArtistSummay?.length > 0) && <div className="verticalMediumWide hover-box">
                                <div className="ws-6 mtxl ptl mll pll hover-block textboxDropdownList box defaultColor rightReset">
                                    <span className='ws-12 text-bold pull-right pb'>Top Artists</span>
                                    {ArtistSummay?.map((value:any , index :any)=>{
                                        return(
                                            <div className='ws-12 box pm mb pull-right'>  
                                                <span className='ws-6 text-bold' style={{color : CommonUtilities.chartColors[index] }}> {value.assetID} </span>
                                                <span className='ws-6 pull-right'> { ( Number(value.totalExp) + Number(value.totalRev) + Number(value.totalRoy))?.toFixed(2) } </span>
                                            </div>
                                        )
                                    })}
                                </div>

                                <a className="btn-info pull-right pt noBorder"><i className="icon-info1 m0 p0"></i></a>
                            </div>}
                            
                        </div>

                        {ArtistSummay?.length > 0 && <div className='ws-12 allowHscroll allowVscroll' >                            
                            <table className="table tablePresentation">
                                <thead>
                                    <tr>  
                                        <th>Artist Name</th> 
                                        <th className="text-right">Total Gross</th>
                                        <th className="text-right hide">Total PaidOut</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {ArtistSummay?.map((listing :any)=>{
                                    return (
                                        <tr>                                         
                                            <td className="text-uppercase">{listing.assetArtist}</td> 
                                            <td className="text-uppercase text-right">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)}  {listing.totalGross?.toFixed(2)}</td> 
                                            <td className="text-uppercase text-right hide">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)}  {listing.totalPaidOut?.toFixed(2)}</td>                                                 
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            <div className="ws-12 ptl">
                            {(() => {
                                if (TATtotalRow > 10? true : false) {
                                    return <div className="ptl pll pbl"><Pagination onChange={paginationTAT} totalRows={TATtotalRow} /></div>
                                }
                            })()}
                            </div>
                            
                        </div>}

                        {(ArtistSummay && ArtistSummay?.length == 0) && <div className="ws-12"><span className="text-center text-bold ws-12 mtl grey">  Not actioned. </span></div>} 
                    </div>
                </div>    

                {<div className="ws-6 mbl p1 prl">
                    <h2 className={ ArtistSummay && ArtistSummay?.length == 0 ? 'grey': ''}>Top Artist</h2>
                    <div className='box ws-12 pt pbl'> 
                        <div className='ws-12'>

                            {(ArtistSummay?.length > 0) && <div className="verticalMediumWide hover-box">
                                <div className="ws-6 mtxl ptl mll pll hover-block textboxDropdownList box defaultColor rightReset mr"> 
                                    <div className="allowHscroll allowVscroll full-height-vh40"> 
                                        <span className='ws-12 text-bold pull-right pb'>Top Artist</span>
                                        <span className='ws-6 pull-left pb pt'>Artist Name</span>
                                        <span className='ws-3 pull-right pb pt'>Gross</span>
                                        <span className='ws-3 pull-right pb pt hide'>PaidOut</span>
                                        <div className="allowHscroll allowVscroll ws-12"> 
                                        {ArtistSummay?.map((value:any , index :any)=>{
                                            return(
                                                <div className='ws-12 box pm mb pull-right'>  
                                                    <span className='ws-6 text-bold purple' > {value.assetArtist} </span> 
                                                    <span className='ws-3 pull-right'style={{color:"green"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)}  { value.totalGross?.toFixed(2) } </span>
                                                    <span className='ws-3 pull-right hide'style={{color:"red"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)}  { value.totalPaidOut?.toFixed(2) } </span>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>

                                </div>

                                <a className="btn-info pull-right pt noBorder"><i className="icon-info1 m0 p0"></i></a>
                            </div>}

                            <div className='ws-12 allowVscroll pLarge pts'> 
                                <HorizontalBarChart chartPassedData={ CallChart(ArtistSummay,"Artist") }/>
                            </div> 

                        </div>
                    </div>
                </div>}

                {<div className="ws-12 mbl prl  hide">

                    <h2 className={ AssetSummay && AssetSummay?.length == 0 ? 'grey': ''}>Top Assets</h2>
                    <div className='box pbl'>

                        {(AssetSummay?.length> 0) && <div className='ws-12 allowHscroll allowVscroll' >
                            <table className="table tablePresentation ">
                                <thead>
                                    <tr className="forceSingleLine"> 
                                        <th>Asset Code</th>
                                        <th>Asset Title</th> 
                                        <th className="text-right">Total Gross</th>
                                        <th className="text-right hide">Total PaidOut</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { AssetSummay?.map((listing :any)=>{
                                    return (
                                        <tr className="forceSingleLine">                                        
                                            <td className="text-uppercase">{listing.assetID}</td>
                                            <td className="text-uppercase">{listing.assetTitle}</td> 
                                            <td className="text-uppercase text-right">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)}  {listing.totalGross?.toFixed(2)}</td> 
                                            <td className="text-uppercase text-right hide">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)}  {listing.totalPaidOut?.toFixed(2)}</td>                                           
                                        </tr>
                                    )
                                }) }
                                </tbody>
                            </table>    

                            <div className="ws-12 ptl pll pbl">
                            {  (() => {
                                if (TAStotalRow > 10? true : false) {
                                    return <div className="ptl"><Pagination onChange={paginationTAS} totalRows={TAStotalRow} /></div>
                                }
                            })() }
                            </div>

                        </div>}
                        
                        {(AssetSummay && AssetSummay?.length == 0) && <div className="ws-12"><span className="text-center text-bold ws-12 mtl grey"> Not actioned. </span></div>} 
                    </div>
                </div>}

                {<div className="ws-6 mbl p1 prl">
                    <h2 className={ AssetSummay && AssetSummay?.length == 0 ? 'grey': ''}>Top Assets</h2>
                    <div className='box ws-12 pt pbl'> 
                        <div className='ws-12'>

                            {(AssetSummay?.length > 0) && <div className="verticalMediumWide hover-box">
                                <div className="ws-9 mtxl ptl mll pll hover-block textboxDropdownList box defaultColor rightReset mr"> 
                                    <div className="allowHscroll allowVscroll full-height-vh40"> 
                                        <span className='ws-12 text-bold pull-right pb'>Top Assets</span>
                                        <span className='ws-6 pull-left pb pt'>Asset Title</span>
                                        <span className='ws-6 pull-right pb pt'>Gross</span>
                                        <span className='ws-3 pull-right pb pt hide'>PaidOut</span>
                                        <div className="allowHscroll allowVscroll ws-12"> 
                                        {AssetSummay?.map((value:any , index :any)=>{
                                            return(
                                                <div className='ws-12 box pm mb pull-right'>  
                                                    <span className='ws-6 text-bold purple' > {value.assetTitle} </span> 
                                                    <span className='ws-2 pull-right text-right'style={{color:"green"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {value.totalGross?.toFixed(2) } </span>
                                                    <span className='ws-3 pull-right hide'style={{color:"red"}}>   {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {value.totalPaidOut?.toFixed(2) } </span>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>

                                </div>

                                <a className="btn-info pull-right pt noBorder"><i className="icon-info1 m0 p0"></i></a>
                            </div>}

                            <div className='ws-12 allowVscroll pLarge pts'> 
                                <HorizontalBarChart chartPassedData={ CallChart(AssetSummay,"Asset") }/>
                            </div> 

                        </div>
                    </div>
                </div>}

                {<div className="ws-12 mbl prl hide"> 
                    <h2>Top Extract</h2>
                    <div className="box pbl"> 
                        {(TopExtract && TopExtract?.length > 0) && <div className='ws-12 allowHscroll allowVscroll'>
                            
                            <table className="table tablePresentation">
                                <thead>
                                    <tr className="forceSingleLine"> 
                                        <th>Asset Code</th>
                                        <th>Asset Title</th>                                            
                                        <th>Artist Name</th>
                                        <th>Territory Code</th>
                                        <th>Format Code</th>
                                        <th>Account Code</th>
                                        <th>Royalty Period</th>
                                        <th>Total Gross</th>
                                        <th className="hide">Total PaidOut</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { TopExtract?.map((listing :any)=>{
                                    return (
                                        <tr>                                        
                                            <td className="text-uppercase">{listing.assetID}</td>
                                            <td className="text-uppercase">{listing.assetTitle}</td>
                                            <td className="text-uppercase">{listing.assetArtist}</td>
                                            <td className="text-uppercase">{listing.territoryCode}</td>
                                            <td className="text-uppercase">{listing.formatCode}</td>
                                            <td className="text-uppercase">{listing.accountCode}</td>
                                            <td className="text-uppercase">{listing.royaltyPeriod}</td>
                                            <td className="text-uppercase text-right">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {listing.totalGross?.toFixed(2)}</td> 
                                            <td className="text-uppercase text-right hide">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {listing.totalPaidOut?.toFixed(2)}</td>                                                 
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table> 

                            <div className="ws-12 ptl pll">
                            { (() => {
                                if (TEtotalRow > 10? true : false) { return <div className="ptl"><Pagination onChange={paginationTE} totalRows={TEtotalRow} /></div>  }
                            })()}
                            </div>
                        </div>}

                        {(TopExtract && TopExtract?.length == 0) && <div className="ws-12 "><span className="text-center text-bold ws-12 mtl grey"> Not actioned. </span></div>} 

                    </div>
                </div>} 


                {<div className="ws-6 mbl p1 prl">
                    <h2 className={ TerritorySummay && TerritorySummay?.length == 0 ? 'grey': ''}>Top Territory</h2>
                    <div className='box ws-12 pt pbl'> 
                        <div className='ws-12'>

                            {(TerritorySummay?.length > 0) && <div className="verticalMediumWide hover-box">
                                <div className="ws-8 mtxl ptl mll pll hover-block textboxDropdownList box defaultColor rightReset mr"> 
                                    <div className="allowHscroll allowVscroll full-height-vh40"> 
                                        <span className='ws-12 text-bold pull-right pb'>Top Territory</span>
                                        <span className='ws-6 pull-left pb pt'>Territory Code</span>
                                        <span className='ws-3 pull-right pb pt'>Gross</span>
                                        <span className='ws-3 pull-right pb pt hide'>PaidOut</span>
                                        <div className="allowHscroll allowVscroll ws-12"> 
                                        {TerritorySummay?.map((value:any , index :any)=>{
                                            return(
                                                <div className='ws-12 box pm mb pull-right'>  
                                                    <span className='ws-6 text-bold purple' > {CommonUtilities.lookUpTypeListByKey("Territory",value.territoryCode,'value')} </span> 
                                                    <span className='ws-3 pull-right'style={{color:"green"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} { value.totalGross?.toFixed(2) } </span>
                                                    <span className='ws-3 pull-right hide'style={{color:"red"}}>   {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} { value.totalPaidOut?.toFixed(2) } </span>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>

                                </div>

                                <a className="btn-info pull-right pt noBorder"><i className="icon-info1 m0 p0"></i></a>
                            </div>}

                            <div className='ws-12 allowVscroll pLarge pts'> 
                                <HorizontalBarChart chartPassedData={ CallChart(TerritorySummay,"Territory") }/>
                            </div> 

                        </div>
                    </div>
                </div>}

                {<div className="ws-6 mbl mbl p1 prl">
                    <h2 className={ AssetSummay && AssetSummay?.length == 0 ? 'grey': ''}>
                        Top Format
                    </h2>
                    <div className='box ws-12 pt pbl'>  
                        
                        <div className='ws-12'>
                            
                            {(FormatSummay?.length > 0) && <div className="verticalMediumWide hover-box">
                                <div className="ws-8 mtxl ptl mll pll hover-block textboxDropdownList box defaultColor rightReset">
                                    <div className="allowHscroll allowVscroll full-height-vh40"> 
                                        <span className='ws-12 text-bold pull-right pb'>Top Format</span>
                                        <span className='ws-6 pull-left pb pt'>Format Code</span>
                                        <span className='ws-3 pull-right pb pt'>Gross</span>
                                        <span className='ws-3 pull-right pb pt hide'>PaidOut</span>
                                        { FormatSummay?.map((value:any , index :any)=>{
                                            return(
                                                <div className='ws-12 box pm mb pull-right'>                                                    
                                                    <span className='ws-6 text-bold purple' > {CommonUtilities.lookUpTypeListByKey("Format",value.formatCode,'value')}</span>
                                                    <span className='ws-3 pull-right'style={{color:"green"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} { value.totalGross?.toFixed(2) } </span>
                                                    <span className='ws-3 pull-right hide'style={{color:"red"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} { value.totalPaidOut?.toFixed(2) } </span>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>

                                <a className="btn-info pull-right pt noBorder"><i className="icon-info1 m0 p0"></i></a>
                            </div>}

                            <div className='ws-12 allowVscroll pLarge pts'> 
                                <HorizontalBarChart chartPassedData={ CallChart(FormatSummay,"Format") } />
                            </div> 

                        </div>
                    </div>
                </div>}

                {<div className="ws-6 mbl p1 prl hide">
                    <div className='box ws-12 pt pbl'>  
                        <span className="ws-12 text-bold pb text-center">Top DSP </span> 

                        <div className='ws-12'>

                            {(DSPSummay?.length > 0) && <div className="verticalMediumWide hover-box">
                                <div className="ws-6 mtxl ptl mll pll hover-block textboxDropdownList box defaultColor rightReset">
                                    <span className='ws-12 text-bold pull-right pb'>Top DSP</span>
                                    <span className='ws-6 pull-right pb pt hide'>PaidOut</span>
                                    <span className='ws-6 pull-right pb pt'>Gross</span>
                                    { DSPSummay?.map((value:any , index :any)=>{
                                        return(                                                
                                        <div className='ws-12 box pm mb pull-right'>  
                                            <span className='ws-6 text-bold' style={{color : CommonUtilities.chartColors[index] }}> {value.accountCode} {value.royaltyPeriod}</span>
                                            <span className='ws-6 pull-left'style={{color:"green"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} { value.totalGross?.toFixed(2) } </span>
                                            <span className='ws-6 pull-right hide'style={{color:"red"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} { value.totalPaidOut?.toFixed(2) } </span>
                                        </div>
                                        )
                                    })  }
                                </div>

                                <a className="btn-info pull-right pt noBorder"><i className="icon-info1 m0 p0"></i></a>
                            </div>}
                            <div className='ws-12 allowVscroll'>
                                <HorizontalBarChart chartPassedData={ CallChart(DSPSummay,"DSP") }/>
                            </div>

                        </div>
                    </div>
                </div>}

                {<div className="ws-12 mbl mbl p1 prl">
                    <h2 className={ AssetSummay && AssetSummay?.length == 0 ? 'grey': ''}>
                        Top Imprint
                    </h2>
                    <div className='box ws-12 pt pbl'>  
                        <span className="ws-12 text-bold pb text-center"></span>
                        
                        <div className='ws-12'>
                            {(ImprintSummay?.length > 0) && <div className="verticalMediumWide hover-box">
                                <div className="ws-5 mtxl ptl mll pll hover-block textboxDropdownList box defaultColor rightReset">
                                    <div className="allowHscroll allowVscroll full-height-vh40"> 
                                        <span className='ws-12 text-bold pull-right pb'>Top Imprint</span>
                                        <span className='ws-3 pull-right pb pt hide'>PaidOut</span>
                                        <span className='ws-6 pull-right pb pt'>Gross</span>
                                        { ImprintSummay?.map((value:any , index :any)=>{
                                            return(
                                            <div className='ws-12 box pm mb pull-right'>  
                                                <span className='ws-6 text-bold' style={{color : CommonUtilities.chartColors[index] }}> {value.assetImprint} </span>
                                                <span className='ws-6 pull-left'style={{color:"green"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {value.totalGross?.toFixed(2) } </span>
                                                <span className='ws-3 pull-right hide'style={{color:"red"}}> {CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {value.totalPaidOut?.toFixed(2) } </span>
                                            </div>
                                            )
                                        }) }
                                    </div>
                                </div>

                                <a className="btn-info pull-right pt noBorder"><i className="icon-info1 m0 p0"></i></a>
                            </div>}

                            <div className='ws-12 pLarge'>
                                <HorizontalBarChart chartPassedData={ CallChart(ImprintSummay,"Imprint") }/>
                            </div>
                                
                        </div>
                    </div>
                </div>}

                {(activePerformanceTab != "Performance") && <div className="ws-12 mbl prl"> 
                    <h2>Top Imprint/CustomID</h2>
                    <div className="box pbl"> 
                        {(ImpCusSummay && ImpCusSummay?.length > 0) && <div className='ws-12 allowHscroll allowVscroll' >
                            
                            <table className="table tablePresentation">
                                <thead>
                                    <tr className="forceSingleLine">  
                                        <th>Asset Imprint</th>
                                        <th>Custom ID</th>  
                                        <th>Total Gross</th>
                                        <th className="hide">Total PaidOut</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { ImpCusSummay?.map((listing :any)=>{
                                    return (
                                        <tr>                                         
                                            <td className="text-uppercase">{listing.assetImprint}</td>
                                            <td className="text-uppercase">{listing.customID}</td>  
                                            <td className="text-uppercase text-right">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {listing.totalGross?.toFixed(2)}</td> 
                                            <td className="text-uppercase text-right hide">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {listing.totalPaidOut?.toFixed(2)}</td>                                                
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table> 

                            <div className="ws-12 ptl">
                            {(() => {
                                if (TopImpCusRows > 10? true : false) { return <div className="ptl pll"><Pagination onChange={paginationTIC} totalRows={TopImpCusRows} /></div>  }
                            })()}
                            </div>
                        </div>}

                        {(ImpCusSummay && ImpCusSummay?.length == 0) && <div className="ws-12 "><span className="text-center text-bold ws-12 mtl grey"> Not actioned. </span></div>} 

                    </div>
                </div>}


                {(activePerformanceTab != "Performance") && <div className="ws-12 mbl prl"> 
                    <h2>Top Territory/Format</h2>
                    <div className="box pbl"> 
                        {(TerritoryFormatSummay && TerritoryFormatSummay?.length > 0) && <div className='ws-12 allowHscroll allowVscroll' >
                            
                            <table className="table tablePresentation">
                                <thead>
                                    <tr className="forceSingleLine">  
                                        <th>Territory Code</th>
                                        <th>Format Code</th> 
                                        <th>Royalty Period</th>
                                        <th>Total Gross</th>
                                        <th className="hide">Total PaidOut</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { TerritoryFormatSummay?.map((listing :any)=>{
                                    return (
                                        <tr>                                         
                                            <td className="text-uppercase">{listing.territoryCode}</td>
                                            <td className="text-uppercase">{listing.formatCode}</td> 
                                            <td className="text-uppercase">{listing.royaltyPeriod}</td>
                                            <td className="text-uppercase text-right">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {listing.totalGross?.toFixed(2)}</td> 
                                            <td className="text-uppercase text-right hide">{CommonUtilities.getCSymbols( global.clientDetail.homeCurrency)} {listing.totalPaidOut?.toFixed(2)}</td>                                                 
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table> 

                            <div className="ws-12 ptl">
                            {(() => {
                                if (TETfotalRows > 10? true : false) { return <div className="ptl pll"><Pagination onChange={paginationTTF} totalRows={TETfotalRows} /></div>  }
                            })()}
                            </div>
                        </div>}

                        {(TerritoryFormatSummay && TerritoryFormatSummay?.length == 0) && <div className="ws-12 "><span className="text-center text-bold ws-12 mtl grey"> Not actioned. </span></div>} 

                    </div>
                </div>}

            </div>
        </div>          

        {(() => {
            if (periodSelection == true) {
                return <PeriodSelectionForm open={periodSelection} closePeriod={closePeriod} onChange={onChange} periodFrom={Filter.convertToDateString(reportingFrom)}  periodTo={Filter.convertToDateString(reportingTo)} />;
            } 
            if (dialogueOpenView) {
                return (<ContainerSlider onChangeClose={onChangeClose} open={dialogueOpenView} selectedDetail={param.selectedDetail} type={param?.DAOpen}/>)
            }
            
        })()} 
    </>)
}